$blue-2f5 : #2f56a1;

.simple-button {
  margin-left: 50px;
  border: 2px solid $blue-2f5 !important;
  color: $blue-2f5 ;
  width: 100px;

  &.hover {
    background-color: None;
  }
}